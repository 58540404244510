import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import { LinearProgress, Table, TextField, Checkbox, Select, MenuItem } from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { debounce } from 'lodash';


const useStyles = makeStyles(theme => ({
  dialogTitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
  content: {
    padding: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 500,
  },
}));

const WhsArea = observer(({ inventorys, size = "small", selected, onDone }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [whsArea, setWhsArea] = useState(null);

  const { loading, areas, addWhsArea, updateWhsArea, deleteWhsArea, setDeliveryCodeMap, setReadyToShip, setHasWO, setDefauleWhsArea } = inventorys;

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    whsArea.id ? await updateWhsArea(whsArea.id, whsArea) : await addWhsArea(whsArea)
    setWhsArea(null);
  }

  const handlePropertyChange = (e) => {
    const { name, value } = e.target;
    const copy = { ...whsArea };
    copy[name] = value;
    setWhsArea(copy);
  }

  const handleDelete = (id) => {
    // add confirm dialog
    if (window.confirm('Are you sure you want to delete this warehouse area?')) {
      deleteWhsArea(id);
    }
  }

  const canSubmit = () => {
    return whsArea?.name && whsArea?.code && !loading;
  }

  const debounceHandleDeliveryCodeMapChange = debounce((id, value) => setDeliveryCodeMap(id, value), 1000);

  return <>
    <Button size={size} variant="contained" color="primary" onClick={handleOpen}>Warehouse Areas</Button>
    {open && <Dialog
      fullWidth
      maxWidth="lg"
      open={open}
      onClose={handleClose}
    >
      <DialogContent>

        {loading ? <Box mb={2} ><LinearProgress></LinearProgress> </Box> : null}

        <Box mb={2} display={'flex'} className={classes.dialogTitle}>
          <Box mr={2} className={classes.dialogTitle}>
            Warehouse Areas
          </Box>
          <Button variant="contained" size="small" color="primary" onClick={() => setWhsArea({
            name: '',
            code: ''
          })}><AddIcon /> Add
          </Button></Box>
        {
          whsArea && <form onSubmit={handleSubmit}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              {/* <Box mb={2} className={classes.subtitle}>Add new wareHouse area</Box> */}
              <Box mb={2}>
                <FormControl required>
                  <TextField name="name" disabled={loading} label="name" value={whsArea.name} onChange={handlePropertyChange} />
                </FormControl>
              </Box>
              <Box mb={2}>
                <FormControl required>
                  <TextField name="code" disabled={loading} label="Code" value={whsArea.code} onChange={handlePropertyChange} />
                </FormControl>
              </Box>

              <Box display={'flex'}>
                <Box>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={!canSubmit()}
                  >
                    Confirm
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button
                    type="button"
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={() => setWhsArea(null)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        }
        <Box mt={1}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell width={'40%'}>Auto assign Delivery Code Map</TableCell>
                <TableCell>Auto assign Ready to Ship</TableCell>
                <TableCell>Auto assign has WO</TableCell>
                <TableCell>Default</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {areas.map((area, idx) => (
                <TableRow key={area.id}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>{area.name}</TableCell>
                  <TableCell>{area.code}</TableCell>
                  {/* <TableCell>
                    <Checkbox size="small" color="primary" checked={area.is_default} onChange={() => setDefauleWhsArea(area.id)} />
                  </TableCell> */}
                  <TableCell>
                    {/* a input string */}
                    <TextField style={{ width: '100%' }} defaultValue={area.delivery_code_map} onChange={(e) => debounceHandleDeliveryCodeMapChange(area.id, e.target.value)} />
                  </TableCell>
                  <TableCell>
                    {/* <Checkbox size="small" color="primary" checked={area.auto_assign_ready_to_ship} onChange={(e) => setReadyToShip(area.id, e.target.checked)} /> */}
                    <Select style={{ width: '100%' }} value={area.auto_assign_ready_to_ship} onChange={(e) => setReadyToShip(area.id, e.target.value)}>
                      <MenuItem value={2}>&nbsp;</MenuItem>
                      <MenuItem value={1}>True</MenuItem>
                      <MenuItem value={0}>False</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    {/* <Checkbox size="small" color="primary" checked={area.auto_assign_whs_work_order} onChange={(e) => setHasWO(area.id, e.target.checked)} /> */}
                    <Select style={{ width: '100%' }} value={area.auto_assign_whs_work_order} onChange={(e) => setHasWO(area.id, e.target.value)}>
                      <MenuItem value={2}>&nbsp;</MenuItem>
                      <MenuItem value={1}>True</MenuItem>
                      <MenuItem value={0}>False</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Checkbox size="small" color="primary" checked={area.is_default} onChange={() => setDefauleWhsArea(area.id)} />
                  </TableCell>
                  <TableCell>
                    <EditIcon onClick={() => setWhsArea(area)} />
                    <DeleteIcon onClick={() => handleDelete(area.id)} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </DialogContent>
    </Dialog>}
  </>
});



export default WhsArea;

