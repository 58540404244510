import { types, getParent } from 'mobx-state-tree';
import DocumentStore from '../document/DocumentsStore';
import { uniq, get } from 'lodash';

const InventoryGroupRowLoad = types.model({
  id: 0,
  uid: types.maybeNull(types.string),
  receiver_shipment_id: types.maybeNull(types.string),
  is_ready_to_ship: types.maybeNull(types.boolean),
  type_name: types.maybeNull(types.string),
});

const InventoryGroupRowBol = types.model({
  id: 0,
  uid: types.maybeNull(types.string),
  delivered_at: types.maybeNull(types.string),
});

const InventoryGroupRowInbound = types.model({
  id: 0,
  uid: types.maybeNull(types.string),
  received_at: types.maybeNull(types.string),
});

export const WhsArea = types.model({
  id: types.number,
  name: types.string,
  code: types.string,
  is_default: types.boolean,
  delivery_code_map: types.maybeNull(types.string),
  auto_assign_ready_to_ship: types.maybeNull(types.number),
  auto_assign_whs_work_order: types.maybeNull(types.number),
})

const InventoryGroupRowWorkOrder = types.model({
  id: 0,
  uid: types.maybeNull(types.string),
  completed_at: types.maybeNull(types.string),
});

export const InventoryGroupRow = types.model({
  id: 0,
  uid: types.maybeNull(types.string),
  loads: types.array(InventoryGroupRowLoad),
  inbounds: types.array(InventoryGroupRowInbound),
  bols: types.array(InventoryGroupRowBol),
  ib_number: types.maybeNull(types.string),
  marking: types.maybeNull(types.string),
  aging: types.maybeNull(types.union(types.number, types.string)),
  status_name: types.maybeNull(types.string),
  total_current_qty: types.maybeNull(types.union(types.number, types.string)),
  total_actual_qty: types.maybeNull(types.union(types.number, types.string)),
  total_qty: types.maybeNull(types.union(types.number, types.string)),
  total_inbound_pallet_count: types.maybeNull(types.union(types.number, types.string)),
  total_whs_pallet_count: types.maybeNull(types.union(types.number, types.string)),
  total_shipout_pallet_count: types.maybeNull(types.union(types.number, types.string)),
  total_remaining_pallet_count: types.maybeNull(types.union(types.number, types.string)),
  total_estimate_pallet_count: types.maybeNull(types.union(types.number, types.string)),
  delivery_codes: types.array(types.string),
  whs_work_orders: types.array(InventoryGroupRowWorkOrder),
  area: types.array(types.string),
  memo: types.maybeNull(types.string),
  total_weight_lb: types.maybeNull(types.union(types.number, types.string)),
  total_volume_cbm: types.maybeNull(types.union(types.number, types.string)),
  whsDocuments: types.optional(DocumentStore, { target: 'inventory_whs' }),
  is_ready_to_ship: types.boolean,
})
  .views(self => ({
    get delivered_bols() {
      return self.bols.filter(o => o.delivered_at);
    },
    get receiver_shipment_ids() {
      return self.loads.map(l => l.receiver_shipment_id).filter(i => i);
    },
  }))
  .actions(self => ({
    loadDocuments() {
      self.whsDocuments.load(self.id);
    },
  }))


export const GroupAreaItem = types
  .model({
    id: types.maybeNull(types.number),
    uid: types.maybeNull(types.string),
    area_id: types.maybeNull(types.union(types.number, types.string)),
    area_name: types.maybeNull(types.string),
    moved_at: types.maybeNull(types.string),
  });

export const GroupArea = types
  .model({
    collection: types.array(GroupAreaItem),
  })
  .views(self => ({
    get area_id() {
      return get(self.collection.map(c => c.area_id).filter(i => i !== null), '0', null);
    },
    get area_name() {
      return get(self.collection.map(c => c.area_id).filter(i => i), '0', null);
    },
    get moved_at() {
      return get(self.collection.map(c => c.moved_at).filter(i => i), '0', null);
    },
  }))
  .volatile(self => ({
    loading: false,
  }))
  .actions(self => ({
    isAllSameArea() {
      return uniq(self.collection.map(c => c.area_id)).length <= 1;
    },
    setLoading(indicator) {
      self.loading = Boolean(indicator);
    },
    setCollection(collection) {
      self.collection = collection;
    },
    move(areaId, time = null) {
      getParent(self).move(self.collection.map(c => c.id), areaId, time);
    },
  }));
