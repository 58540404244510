import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Badge from "../../../common/Badge";
import Table from "../../../ui/Table";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";
import CollectionText from "../../../common/CollectionText";
import ItemEditable from "./ItemEditable";
import Memo from "./Memo";
import { DuplicateIcon, WHSTaskIcon } from "../../../ui/Icon";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Link } from "react-router-dom";
import GroupBy from "./GroupBy";
import clsx from "clsx";
import EstimatePalletCount from "./EstimatePalletCount";
import { getSnapshot } from "mobx-state-tree";
import WhsLoads from "./WhsLoads";
import AddonServicesTable from "../../addonService/AddonServicesTable";
import ItemSelectable from "./ItemSelectable";
import { IconButton, Popover } from "@material-ui/core";

const WhsTasksCollectionText = withStyles((theme) => ({
  root: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.5,
    textDecorationLine: "underline",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}))(CollectionText);

const WhsWorkOrdersCollectionText = withStyles((theme) => ({
  root: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.5,
    textDecorationLine: "underline",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}))(CollectionText);


const BolNumbersCollectionText = withStyles((theme) => ({
  root: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.5,
    textDecorationLine: "underline",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
}))(CollectionText);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "21px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "300px",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "450px",
    },
  },
  subRows: {
    backgroundColor: "#FFFFFF",
  },
  footer: {
    fontWeight: "500 !important",
  },
  groupedBy: {
    color: theme.palette.primary.main,
  },
  tableTitle: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: theme.palette.primary.main,
    marginBottom: "10px",
  },
}));

const groupColumns = [
  {
    Header: ({
      getToggleAllRowsExpandedProps,
      isAllRowsExpanded,
      state: { classes, inbound },
    }) => (
      <>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          {...getToggleAllRowsExpandedProps()}
        >
          <Box
            className={clsx({ [classes.groupedBy]: inbound.isGroupByMarking })}
          >
            IB Marking
          </Box>
          <Box>
            {isAllRowsExpanded ? (
              <ArrowDropUpIcon style={{ fontSize: "21px" }} />
            ) : (
              <ArrowDropDownIcon style={{ fontSize: "21px" }} />
            )}
          </Box>
        </Box>
      </>
    ),
    accessor: "markings",
    Cell: ({
      state: { inbound },
      row: {
        original,
        canExpand,
        isExpanded,
        getToggleRowExpandedProps,
        depth,
      },
    }) => (
      <>
        {depth == 0 ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            {...getToggleRowExpandedProps()}
          >
            <Box mr={1}>
              {original.is_oversize && (
                <Badge pill color="warning">
                  OS
                </Badge>
              )}
              {original.is_overweight && (
                <Badge pill color="warning">
                  OW
                </Badge>
              )}
            </Box>
            <Box mr={1}>
              {original.has_whs_work_order && (
                <WHSTaskIcon color="primary" style={{ fontSize: "21px" }} />
              )}
            </Box>
            <Box>
              <CollectionText texts={original.markings} />
            </Box>
            {canExpand && (
              <Box width={24} ml={1}>
                {isExpanded ? (
                  <ArrowDropUpIcon style={{ fontSize: "21px" }} />
                ) : (
                  <ArrowDropDownIcon style={{ fontSize: "21px" }} />
                )}
              </Box>
            )}
          </Box>
        ) : (
          <>
            {depth > 0 && !inbound.isGroupByMarking && (
              <Box>
                <CollectionText texts={original.markings} />
              </Box>
            )}
          </>
        )}
      </>
    ),
    Footer: "Total",
  },
  {
    Header: ({ state: { inbound, classes } }) => (
      <Box
        className={clsx({ [classes.groupedBy]: inbound.isGroupByDestination })}
      >
        Destination
      </Box>
    ),
    accessor: "delivery_codes",
    Cell: ({
      row: {
        original,
        canExpand,
        isExpanded,
        getToggleRowExpandedProps,
        depth,
      },
    }) => (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...getToggleRowExpandedProps()}
      >
        {depth > 0 && (
          <Box mr={1}>
            {original.is_oversize && (
              <Badge pill color="warning">
                OS
              </Badge>
            )}
            {original.is_overweight && (
              <Badge pill color="warning">
                OW
              </Badge>
            )}
          </Box>
        )}
        <Box>
          <CollectionText texts={original.delivery_codes} />
        </Box>
        {canExpand && depth == 1 && (
          <Box width={24} ml={1}>
            {isExpanded ? (
              <ArrowDropUpIcon style={{ fontSize: "21px" }} />
            ) : (
              <ArrowDropDownIcon style={{ fontSize: "21px" }} />
            )}
          </Box>
        )}
        {depth > 0 && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box
              ml={1}
            >{`${original.receiver_shipment_id} ${original.customer_reference}`}</Box>
          </Box>
        )}
      </Box>
    ),
  },
  {
    Header: "Qty",
    accessor: "qty",
    Footer: (table) => {
      return table.state.detail.group_total_qty;
    },
  },
  {
    Header: "Unit",
    accessor: "unit",
    Cell: ({ row: { original, depth } }) =>
      depth === 0 ? (
        <CollectionText texts={original.units} emptyContent="-" />
      ) : (
        original.unit || "-"
      ),
  },
  {
    Header: "Weight LB",
    accessor: "weight_lb",
    Footer: (table) => {
      return table.state.detail.group_total_weight_lb;
    },
  },
  {
    Header: "CBM",
    accessor: "volume_cbm",
    Footer: (table) => {
      return table.state.detail.group_total_volume_cbm;
    },
  },
  {
    Header: ({ state: { handleSyncReceviedQty, detail } }) => <Box
      display="flex"
      alignItems="center"
      justifyContent="center">
      <span>Received QTY</span>
      <Tooltip title={'Sync Received QTY'}>
        <IconButton size="small">
          <DuplicateIcon onClick={() => handleSyncReceviedQty(detail)} />
        </IconButton>
      </Tooltip>
    </Box>,
    accessor: "actual_qty",
    Cell: ({ row: { original, depth }, state: { superAdmin } }) => (
      <>
        {depth === 0 && !original.completed_at ? (
          <ItemEditable
            value={original.actual_qty}
            onChange={original.updateGroupActualQty}
            disabled={original.actual_qty && original.bols.some((bol) => bol.actual_pickup_time) && !superAdmin}
          />
        ) : original.actual_qty === null ? (
          "-"
        ) : (
          original.actual_qty
        )}
      </>
    ),
    Footer: (table) => {
      return table.state.detail.group_total_actual_qty;
    },
  },
  {
    Header: ({ state: { handleSyncInboundPLTQty, detail } }) => <Box
      display="flex"
      alignItems="center"
      justifyContent="center">
      <span>Inbound PLT Count</span>
      <Tooltip title={'Sync Inbound PLT Count'}>
        <IconButton size="small">
          <DuplicateIcon onClick={() => handleSyncInboundPLTQty(detail)} />
        </IconButton>
      </Tooltip>
    </Box>,
    accessor: "inbound_pallet_count",
    Cell: ({ row: { original, depth, index }, state: { superAdmin } }) => {
      return (
        <>
          {depth === 0 && !original.completed_at ? (
            <ItemEditable
              resigster={`inbound_pallet_count-${index}`}
              value={original.inbound_pallet_count || ""}
              onChange={original.updateGroupInboundPalletCount}
              disabled={original.inbound_pallet_count && original.bols.some((bol) => bol.actual_pickup_time) && !superAdmin}
            />
          ) : original.inbound_pallet_count === null ? (
            "-"
          ) : (
            original.inbound_pallet_count
          )}
        </>
      );
    },
    Footer: (table) => {
      return table.state.detail.group_total_inbound_pallet_count;
    },
  },
  {
    Header: ({ state: { handleSyncWhsArea, detail, loading } }) => {
      return <Box
        display="flex"
        alignItems="center"
        justifyContent="center">
        <span>Whs Area</span>
        <Tooltip title={'Sync Whs Area'}>
          <IconButton size="small" disabled={loading} >
            <DuplicateIcon onClick={() => handleSyncWhsArea(detail)} />
          </IconButton>
        </Tooltip>
      </Box>
    },
    accessor: "whsArea",
    Cell: observer(
      ({ row: { original, depth, index }, state: { inbound } }) => {
        return (
          <>
            {depth === 0 && !original.completed_at ? (
              <ItemSelectable
                resigster={`whs_area-${index}`}
                options={inbound.areaOptions || []}
                value={inbound.areaOptions.filter((o) => {
                  return (
                    // o.text === (original.whsAreas ? original.whsAreas[0] : "")
                    original.whsAreas?.includes(o.text)
                  );
                })}
                onChange={(v) => {
                  original.updateGroupWhsArea(v.map((v) => v.value))
                }}
                multiple={true}
              />
            ) : (
              original.whsAreas?.join(",") || ""
            )}
          </>
        );
      }
    ),
  },
  {
    Header: "WHS PLT Count",
    accessor: (original) =>
      original.whs_pallet_count === null ? "-" : original.whs_pallet_count,
    Footer: (table) => {
      return table.state.detail.group_total_whs_pallet_count;
    },
  },
  {
    Header: "Markup PLT Count",
    accessor: "markup_pallet_count",
    Cell: ({ row: { original, depth, index }, state: { superAdmin } }) => {
      return (
        <>
          {depth === 0 && !original.completed_at ? (
            <ItemEditable
              resigster={`markup_pallet_count-${index}`}
              value={original.markup_pallet_count || ""}
              onChange={original.updateGroupMarkupPalletCount}
              disabled={original.markup_pallet_count && original.bols.some((bol) => bol.actual_pickup_time) && !superAdmin}
            />
          ) : original.markup_pallet_count === null ? (
            "-"
          ) : (
            original.markup_pallet_count
          )}
        </>
      );
    },
    Footer: (table) => {
      return table.state.detail.group_total_markup_pallet_count;
    },
  },
  {
    Header: ({ state: { detail } }) => (
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box>EST PLT Count</Box>
        <EstimatePalletCount detail={detail} />
      </Box>
    ),
    accessor: "estimate_pallet_count",
    Cell: observer(({ row: { original } }) =>
      original.estimate_pallet_count
        ? original.estimate_pallet_count.toFixed(2)
        : "-"
    ),
    Footer: observer(({ state: { detail } }) => {
      return detail.group_total_estimate_pallet_count
        ? detail.group_total_estimate_pallet_count.toFixed(2)
        : "-";
    }),
  },
  {
    Header: "Label",
    accessor: "label",
    Cell: ({ row: { original, depth } }) => (
      <>
        {depth === 0 ? (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={original.viewLabel}
          >
            View
          </Button>
        ) : (
          "-"
        )}
      </>
    ),
  },
  {
    Header: "BOL",
    accessor: "bols",
    Cell: ({ row: { original }, state: { theme } }) => (
      <>
        {original.bols.length > 0 ? (
          <BolNumbersCollectionText
            texts={original.bols}
            taken={1}
            rowRender={(bol) => (
              <span>
                <Link to={`/bols/${bol.id}`}>{bol.uid}</Link>
                {bol.actual_pickup_time && !bol.is_pod && (
                  <Tooltip title="Fully Shipout">
                    <CheckCircleIcon
                      style={{
                        fontSize: 18,
                        color: theme.palette.success.main,
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                )}
                {bol.actual_pickup_time && bol.is_pod && (
                  <Tooltip title="All POD Uploaded">
                    <CheckCircleIcon
                      style={{
                        fontSize: 18,
                        color: theme.palette.secondary.main,
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                )}
              </span>
            )}
          />
        ) : (
          "-"
        )}
      </>
    ),
  },
  {
    Header: "WO",
    accessor: "work_orders",
    Cell: ({ row: { original }, state: { theme } }) => (
      <>
        {original.has_whs_work_order && original.whs_work_orders.length > 0
          ? original.whs_work_orders.map((wo) => (
            <Link to={`/workOrders/${wo.id}`}>
              {wo.uid}
              {wo.completed_at && (
                <CheckCircleIcon
                  style={{
                    fontSize: 18,
                    color: theme.palette.success.main,
                    verticalAlign: "middle",
                  }}
                />
              )}
            </Link>
          ))
          : "-"}
      </>
    ),
  },
  {
    Header: "Ready To Ship",
    accessor: "is_ready_to_ship",
    // Cell: ({ row: { original }, state: { detail } }) => <Switch color="primary" size="small" checked={!!original.is_ready_to_ship} name="is_ready_to_ship" onChange={e => original.switchIsReadyToShip(e.target.checked)} disabled={!!detail.completed_at} />,
    Cell: ({ row: { original }, state: { detail } }) => (
      <>
        {original.is_ready_to_ship ? (
          <Chip size="small" label="Yes" color="primary" />
        ) : (
          <Chip size="small" label="No" />
        )}
      </>
    ),
  },
  {
    Header: "Load Type",
    accessor: "type_name",
    Cell: ({ row: { original, depth } }) => (
      <>
        {depth === 0 && (
          <CollectionText texts={original.type_names} emptyContent="-" />
        )}
        {depth === 1 && original.type_name}
      </>
    ),
  },
  {
    Header: "Memo",
    accessor: "memo",
    Cell: ({ row: { original, depth } }) => (
      <>
        {depth === 0 ? (
          <Memo value={original.memo} onChange={original.updateGroupMemo} />
        ) : (
          "-"
        )}
      </>
    ),
  },
  {
    Header: ({ state }) => <GroupBy inbound={state.inbound} />,
    accessor: "groupBy",
    Cell: () => null,
  },
];

const itemColumns = [
  {
    Header: "SKU",
    accessor: "sku",
  },
  {
    Header: "IB Marking",
    accessor: "marking",
    Footer: () => "Total",
  },
  {
    Header: "Total Item Qty",
    accessor: (original) => original.item_qty || "-",
    Footer: (table) => {
      return table.state.detail.item_total_item_qty;
    },
  },
  {
    Header: "Received Item Qty",
    accessor: "item_actual_qty",
    Cell: ({ row: { original } }) => (
      <>
        {!original.completed_at ? (
          <ItemEditable
            value={original.item_actual_qty}
            onChange={original.updateItemActualQty}
          />
        ) : original.item_actual_qty === null ? (
          "-"
        ) : (
          original.item_actual_qty
        )}
      </>
    ),
    Footer: (table) => {
      return table.state.detail.item_total_item_actual_qty;
    },
  },
  {
    Header: "Weight LB",
    accessor: (original) => original.weight_lb || "-",
    Footer: (table) => {
      return table.state.detail.item_total_weight_lb;
    },
  },
  {
    Header: "CBM",
    accessor: (original) => original.volume_cbm || "-",
    Footer: (table) => {
      return table.state.detail.item_total_volume_cbm;
    },
  },
  {
    Header: "Carton Qty",
    accessor: (original) => original.carton_qty || "-",
    Footer: (table) => {
      return table.state.detail.item_total_carton_qty;
    },
  },
  {
    Header: "Received Carton Qty",
    accessor: "carton_actual_qty",
    Cell: ({ row: { original } }) => (
      <>
        {!original.completed_at ? (
          <ItemEditable
            value={original.carton_actual_qty}
            onChange={original.updateCartonActualQty}
          />
        ) : original.carton_actual_qty === null ? (
          "-"
        ) : (
          original.carton_actual_qty
        )}
      </>
    ),
    Footer: (table) => {
      return table.state.detail.item_total_carton_actual_qty;
    },
  },
  {
    Header: "IB PLT Count",
    accessor: "inbound_pallet_count",
    Cell: ({ row: { original, index } }) => {
      return (
        <>
          {!original.completed_at ? (
            <ItemEditable
              resigster={`inbound_pallet_count-${index}`}
              value={original.inbound_pallet_count || ""}
              onChange={original.updateItemInboundPalletCount}
              disabled={!!original.actual_pickup_time}
            />
          ) : original.inbound_pallet_count === null ? (
            "-"
          ) : (
            original.inbound_pallet_count
          )}
        </>
      );
    },
  },
  {
    Header: "WHS PLT Count",
    accessor: (original) => original.whs_pallet_count || "-",
    Footer: (table) => {
      return table.state.detail.item_total_whs_pallet_count;
    },
  },
  {
    Header: "EST PLT Count",
    accessor: (original) => original.estimate_pallet_count || "-",
    Footer: (table) => {
      return table.state.detail.item_total_estimate_pallet_count;
    },
  },
  // {
  //   Header: ({ state: { inbound, classes } }) => <Box className={clsx({ [classes.groupedBy]: inbound.isGroupByDestination })}>Destination</Box>,
  //   accessor: 'delivery_codes',
  //   Cell: ({ row: { original, canExpand, isExpanded, getToggleRowExpandedProps, depth } }) => <Box
  //     display="flex"
  //     alignItems="center"
  //     justifyContent="center"
  //     {...getToggleRowExpandedProps()}
  //   >
  //     {depth > 0 && <Box mr={1}>
  //       {original.is_oversize && <Badge pill color="warning">OS</Badge>}
  //       {original.is_overweight && <Badge pill color="warning">OW</Badge>}
  //     </Box>}
  //     <Box><CollectionText texts={original.delivery_codes} /></Box>
  //     {canExpand && depth == 1 && <Box width={24} ml={1}>{isExpanded ? <ArrowDropUpIcon style={{ fontSize: '21px' }} /> : <ArrowDropDownIcon style={{ fontSize: '21px' }} />}</Box>}
  //     {depth > 0 && <Box
  //       display="flex"
  //       alignItems="center"
  //       justifyContent="center"
  //     >
  //       <Box ml={1}>{`${original.receiver_shipment_id} ${original.customer_reference}`}</Box>
  //     </Box>}
  //   </Box>,
  // },
  // {
  //   Header: 'Qty',
  //   accessor: 'qty',
  //   Footer: (table) => {
  //     return table.state.detail.total_qty;
  //   },
  // },
  // {
  //   Header: 'Unit',
  //   accessor: 'unit',
  //   Cell: ({ row: { original, depth } }) => depth === 0 ? <CollectionText texts={original.units} emptyContent="-" /> : (original.unit || '-'),
  // },
  // {
  //   Header: 'Weight LB',
  //   accessor: 'weight_lb',
  //   Footer: (table) => {
  //     return table.state.detail.total_weight_lb;
  //   },
  // },
  // {
  //   Header: 'CBM',
  //   accessor: 'volume_cbm',
  //   Footer: (table) => {
  //     return table.state.detail.total_volume_cbm;
  //   },
  // },
  // {
  //   Header: 'Received QTY',
  //   accessor: 'actual_qty',
  //   Cell: ({ row: { original, depth } }) => <>
  //     {(depth === 0 && !original.completed_at)
  //       ? <ItemEditable value={original.actual_qty} onChange={original.updateGroupActualQty} />
  //       : (original.actual_qty === null ? '-' : original.actual_qty)}
  //   </>,
  //   Footer: (table) => {
  //     return table.state.detail.total_actual_qty;
  //   },
  // },
  // {
  //   Header: 'Inbound PLT Count',
  //   accessor: 'inbound_pallet_count',
  //   Cell: ({ row: { original, depth, index } }) => {
  //     return <>
  //       {(depth === 0 && !original.completed_at)
  //         ? <ItemEditable resigster={`inbound_pallet_count-${index}`} value={original.inbound_pallet_count || ''} onChange={original.updateGroupInboundPalletCount} disabled={original.bols.some(bol => bol.actual_pickup_time)} />
  //         : (original.inbound_pallet_count === null ? '-' : original.inbound_pallet_count)}
  //     </>
  //   },
  //   Footer: (table) => {
  //     return table.state.detail.total_inbound_pallet_count;
  //   },
  // },
  // {
  //   Header: 'WHS PLT Count',
  //   accessor: (original) => original.whs_pallet_count === null ? '-' : original.whs_pallet_count,
  //   Footer: (table) => {
  //     return table.state.detail.total_whs_pallet_count;
  //   },
  // },
  // {
  //   Header: ({ state: { detail } }) => <Box display="flex" alignItems="center" justifyContent="center">
  //     <Box>EST PLT Count</Box>
  //     <EstimatePalletCount detail={detail} />
  //   </Box>,
  //   accessor: 'estimate_pallet_count',
  //   Cell: observer(({ row: { original } }) => original.estimate_pallet_count ? original.estimate_pallet_count.toFixed(2) : '-'),
  //   Footer: observer(({ state: { detail } }) => {
  //     return detail.total_estimate_pallet_count ? detail.total_estimate_pallet_count.toFixed(2) : '-';
  //   }),
  // },
  // {
  //   Header: 'Label',
  //   accessor: 'label',
  //   Cell: ({ row: { original, depth } }) => <>
  //     {depth === 0 ? <Button variant="outlined" color="primary" size="small" onClick={original.viewLabel}>View</Button> : '-'}
  //   </>,
  // },
  // {
  //   Header: 'BOL',
  //   accessor: 'bols',
  //   Cell: ({ row: { original }, state: { theme } }) => <>
  //     {original.bols.length > 0 ? <BolNumbersCollectionText texts={original.bols} taken={1} rowRender={bol => <span>
  //       <Link to={`/bols/${bol.id}`}>{bol.uid}</Link>
  //       {bol.actual_pickup_time && !bol.is_pod && <Tooltip title="Fully Shipout"><CheckCircleIcon style={{ fontSize: 18, color: theme.palette.success.main, verticalAlign: 'middle' }} /></Tooltip>}
  //       {bol.actual_pickup_time && bol.is_pod && <Tooltip title="All POD Uploaded"><CheckCircleIcon style={{ fontSize: 18, color: theme.palette.secondary.main, verticalAlign: 'middle' }} /></Tooltip>}
  //     </span>
  //     } /> : '-'}
  //   </>,
  // },
  // {
  //   Header: 'WO',
  //   accessor: 'work_orders',
  //   Cell: ({ row: { original }, state: { theme } }) => <>
  //     {original.has_whs_work_order && original.whs_work_orders.length > 0 ?
  //       original.whs_work_orders.map(wo => <Link to={`/workOrders/${wo.id}`}>
  //         {wo.uid}
  //         {wo.completed_at && <CheckCircleIcon style={{ fontSize: 18, color: theme.palette.success.main, verticalAlign: 'middle' }} />}
  //       </Link>)
  //       : '-'}
  //   </>,
  // },
  // {
  //   Header: 'Ready To Ship',
  //   accessor: 'is_ready_to_ship',
  //   Cell: ({ row: { original }, state: { detail } }) => <Switch color="primary" size="small" checked={!!original.is_ready_to_ship} name="is_ready_to_ship" onChange={e => original.switchIsReadyToShip(e.target.checked)} disabled={!!detail.completed_at} />,
  // },
  // {
  //   Header: 'Load Type',
  //   accessor: 'type_name',
  //   Cell: ({ row: { original, depth }, state: { } }) => <>
  //     {depth === 0 && <CollectionText texts={original.type_names} emptyContent="-" />}
  //     {depth === 1 && original.type_name}
  //   </>,
  // },
  // {
  //   Header: 'Memo',
  //   accessor: 'memo',
  //   Cell: ({ row: { original, depth } }) => <>
  //     {depth === 0 ? <Memo value={original.memo} onChange={original.updateGroupMemo} /> : '-'}
  //   </>,
  // },
  // {
  //   Header: ({ state }) => <GroupBy inbound={state.inbound} />,
  //   accessor: 'groupBy',
  //   Cell: () => null
  // }
];

const Detail = inject("root")(
  observer(({ inbound, root }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { detail, groupBy, loadWhsArea, groupSelected, loading } = inbound;
    useEffect(() => {
      return () => {
        root.inputManager.clear();
      };
    }, [root]);

    useEffect(() => {
      loadWhsArea();
    }, []);

    const setGroupSelected = React.useCallback((selected) => {
      inbound.setGroupSelected(selected.map((s) => getSnapshot(s)));
    }, []);

    const setItemSelected = React.useCallback((selected) => {
      inbound.setItemSelected(selected.map((s) => getSnapshot(s)));
    }, []);

    const handleSyncReceviedQty = (detail) => {
      const groups = groupSelected.length === 0 ? detail.groups : groupSelected;
      const ids = []
      let proceed = true;
      if (groups.some(group => group.loads.some(load => load.actual_qty))) {
        proceed = window.confirm('Some of Received QTY alreay have value, Are you sure to override them?');
      }

      if (!proceed) { return }

      for (const group of groups) {
        for (const load of group.loads) {
          ids.push(load.id);
        }
      }
      inbound.syncGroupQtys(detail.id, ids, 'actual_qty');
    }

    const handleSyncInboundPLTQty = (detail) => {
      const groups = groupSelected.length === 0 ? detail.groups : groupSelected;
      const ids = []
      let proceed = true;
      if (groups.some(group => group.loads.some(load => load.inbound_pallet_count))) {
        proceed = window.confirm('Some of Inbound Pallet Count alreay have value, Are you sure to override them?');
      }

      if (!proceed) { return }

      for (const group of groups) {
        for (const load of group.loads) {
          ids.push(load.id);
        }
      }

      inbound.syncGroupQtys(detail.id, ids, 'inbound_pallet_count');
    }

    const handleSyncWhsArea = (detail) => {
      inbound.syncWhsArea(detail.id);
    }

    return (
      <Box tabIndex="0" className={classes.root}>
        {detail.groups.length > 0 && (
          <>
            <div className={classes.tableTitle}>
              {detail.shipment_type_name} Transload Shipment Inbound (by Loads)
            </div>
            <Table
              selection
              footerable
              onSelectedChange={setGroupSelected}
              showRowSelection={({ row: { depth } }) => depth === 0}
              columns={groupColumns}
              data={detail.groups.slice()}
              getFooterProps={() => ({ className: classes.footer })}
              getSubRows={(row) => {
                if (row) {
                  if (row.loads) {
                    return row.loads.slice();
                  }
                }

                return [];
              }}
              getRowProps={({ depth }) => {
                return depth > 0 ? { className: classes.subRows } : {};
              }}
              state={{ detail, classes, inbound, groupBy, theme, superAdmin: root.auth.user.super_admin, handleSyncReceviedQty, handleSyncInboundPLTQty, handleSyncWhsArea, loading }}
            />
            <WhsLoads inbound={inbound} />
          </>
        )}

        {detail.items.length > 0 && (
          <>
            <Box mt={5} className={classes.tableTitle}>
              Order Fulfillment Inbound (by SKUs)
            </Box>
            <Table
              selection
              footerable
              onSelectedChange={setItemSelected}
              showRowSelection={({ row: { depth } }) => depth === 0}
              columns={itemColumns}
              data={detail.items.slice()}
              getFooterProps={() => ({ className: classes.footer })}
              state={{ detail, classes, inbound, theme, superAdmin: root.auth.user.super_admin }}
            />
          </>
        )}

        {detail.addon_services.length > 0 && (
          <>
            <Box mt={5} className={classes.tableTitle}>
              Addon Service
            </Box>
            <AddonServicesTable data={detail.addon_services} />
          </>
        )}
      </Box>
    );
  })
);

export default Detail;
